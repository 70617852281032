<template>
  <validation-observer
    ref="creditClassTeacherLessonContentSaveFormRef"
    #default="{ invalid }"
  >
    <b-modal
      id="creditClassTeacherLessonContentSaveModal"
      centered
      body-class="position-static"
      :title="isCreated ? `Thêm giảng viên cho tiết học` : `Cập nhật giảng viên cho tiết học`"
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group label-for="subjectLessonContentId">
          <template v-slot:label>
            Tiết học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tiết học"
            rules="required"
          >
            <v-select
              v-model="targetData.subjectLessonContentId"
              :options="lessonContentByCreditClass"
              :reduce="option => option.value"
              :disabled="disableSubjectLessonContentId"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="lessonNum"
        >
          <template v-slot:label>
            Số tiết thực hiện <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Số tiết thực hiện"
            rules="required"
          >
            <b-form-input
              id="lessonNum"
              v-model="targetData.lessonNum"
              name="lessonNum"
              type="number"
              placeholder="Số tiết thực hiện"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="instructionalDate"
        >
          <template v-slot:label>
            Thời gian <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thời gian"
            rules="required"
          >
            <flat-pickr
              v-model="targetData.instructionalDate"
              class="form-control"
              :config="config"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('creditClassTeacherLessonContentSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export default {
  name: 'CreditClassTeacherSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
    checkForm: {
      type: Boolean,
      default: false,
    },
    teacherCreditClassId: {
      type: Number,
      default: undefined,
    },
    creditClassId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: null,
        creditClassId: this.checkForm ? (this.extData.creditClassId || this.$route.params.id) : this.creditClassId,
        teacherCreditClassId: this.teacherCreditClassId,
        subjectLessonContentId: null,
        lessonNum: null,
        instructionalDate: null,
        status: 1,
        orderNo: null,
        delFlag: 0,
      },
      disableSubjectLessonContentId: false,
      required,
      config: {
        altFormat: 'd/m/Y',
        dateFormat: 'd/m/Y',
        locale: FlatpickrLanguages.vn,
      },
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      resourceName: 'creditClassTeacher/resourceName',
      lessonContentByCreditClass: 'subjectLessonContent/lessonContentByCreditClass',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'subjectLessonContent/createTeacherCreditClassLessonContentData',
      updateData: 'subjectLessonContent/updateTeacherCreditClassLessonContentData',
      getLessonContentByCreditClass: 'subjectLessonContent/getLessonContentByCreditClass',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.isCreated) {
        this.disableSubjectLessonContentId = false
      } else {
        this.disableSubjectLessonContentId = true
      }
      try {
        await this.getLessonContentByCreditClass({
          creditClassId: this.targetData.creditClassId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
      if (this.mainData) {
        this.targetData = this.mainData
        this.targetData.subjectLessonContentId = this.mainData.subjectLessonContentId
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .creditClassTeacherLessonContentSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        creditClassId: this.checkForm ? (this.extData.creditClassId || this.$route.params.id) : this.creditClassId,
        teacherCreditClassId: this.teacherCreditClassId,
        subjectLessonContentId: [],
        status: 1,
        orderNo: null,
        delFlag: 0,
      }
    },
    async onSave(type = null) {
      const valid = await this.$refs
        .creditClassTeacherLessonContentSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData( this.targetData )
            : await this.updateData( this.targetData )
          if ( response ) {
            const {
              isSuccessful,
              message,
            } = response
            if ( isSuccessful ) {
              this.$toast( {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              } )
              if ( type === 'hide' ) {
                this.$bvModal.hide( 'creditClassTeacherLessonContentSaveModal' )
              }
              this.$emit( 'succeed' )
            } else {
              this.$toast( {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              } )
            }
          }
        } catch ( e ) {
          this.$toast( {
            component: ToastificationContent,
            props: {
              title: `[${ e.code }] ${ e.message }`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          } )
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
